import { isBefore, startOfDay } from "date-fns";
import { useState, SyntheticEvent } from "react";
import { DayPicker, DayModifiers } from "react-day-picker";

import { Locale } from "types";

import styles from "./LayoutSearchBarDatePicker.module.css";

const LayoutSearchBarDatePicker: React.FC<LayoutSearchBarDatePickerProps> = ({
  locale,
  from,
  to,
  onFromChange,
  onToChange,
  onSelectionCompleted,
  numberOfMonths,
}) => {
  const [enteredTo, setEnteredTo] = useState<Date | null>(null);

  const isSelectingFirstDay = (day: Date) => {
    const isBeforeFirstDay = from && isBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const onDayMouseEnter = (day: Date) => {
    if (!isSelectingFirstDay(day)) {
      setEnteredTo(day);
    }
  };

  const onDayClick = (day: Date, _: DayModifiers, e: SyntheticEvent) => {
    e.preventDefault();
    if (isBefore(day, startOfDay(new Date()))) {
      return;
    }

    if (from && !to && isBefore(day, from)) {
      onFromChange(day);
      setEnteredTo(null);
      return;
    }
    if (from && to && day >= from && day <= to) {
      onFromChange(null);
      onToChange(null);
      setEnteredTo(null);
      return;
    }

    if (isSelectingFirstDay(day)) {
      onFromChange(day);
      onToChange(null);
      setEnteredTo(null);
    } else {
      onToChange(day);
      setEnteredTo(day);
      onSelectionCompleted();
    }
  };

  const now = new Date();

  return (
    <DayPicker
      mode="range"
      classNames={styles}
      selected={{ from, to: enteredTo || to }}
      onDayMouseEnter={onDayMouseEnter}
      weekStartsOn={1}
      disabled={{ before: now }}
      onDayClick={onDayClick}
      modifiersClassNames={{
        range_start: styles.start,
        range_end: styles.end,
      }}
      numberOfMonths={numberOfMonths}
      fromDate={now}
      formatters={{
        formatCaption: (date) =>
          `${new Intl.DateTimeFormat(locale, { month: "long" }).format(
            date,
          )} ${date.getFullYear()}`,
        formatWeekdayName: (day) =>
          new Intl.DateTimeFormat(locale, { weekday: "short" }).format(day),
      }}
      defaultMonth={from || now}
      initialFocus={true}
    />
  );
};

type LayoutSearchBarDatePickerProps = {
  locale: Locale;
  from?: Date;
  to?: Date;
  onFromChange: (date: Date | null) => void;
  onToChange: (date: Date | null) => void;
  onSelectionCompleted: () => void;
  numberOfMonths: number;
};

export default LayoutSearchBarDatePicker;
