// eslint-disable-next-line no-restricted-imports
import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ButtonSkeleton = (props: SkeletonProps) => (
  <Skeleton height={52} {...props} />
);
export const FormInputSkeleton = () => <Skeleton height={50} />;

export default Skeleton;
