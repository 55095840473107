import { isAfter, isBefore } from "date-fns";

import { Brand } from "types";

const promotionStartDate = new Date("2024-11-25T11:00:00+01:00");
const promotionEndDate = new Date("2024-12-09T00:00:00+01:00");

export const isAssetPromotionEnabled = (
  assetId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  brand: Brand,
): boolean => {
  const isAfterPromotionStart = isAfter(new Date(), promotionStartDate);
  const isBeforePromotionEnd = isBefore(new Date(), promotionEndDate);
  return (
    isAfterPromotionStart &&
    isBeforePromotionEnd &&
    [
      "47c83009-ebd0-4cef-9ebb-0ecced24fda5", //LLPR
      "1de5a6ea-1d96-46db-ab77-d031880eeba2", //LBSA
      "167f2b95-95de-4b29-bbab-5a2ab5a6db39", //LBLD
      "6a76ab2c-d9bd-44e4-944b-c036bf6bacc7", //LBMU
      "ba660e94-42a7-4679-abf4-33ebe7108bc8", //LBLV
      "c52a089f-341e-40bb-87a7-a48d95ea56c8", //BBAB
      "242b3676-bb54-47e1-8c1d-73307ec71f75", //LCDR
      "2045c99b-7c4c-4be4-bd3d-b71de803b065", //LCTN
      "2b2c6df4-819d-4ca0-a331-52fe59050a43", //LCSM
      "58b8481b-c493-4024-a0dc-a80430c90ce0", //LGCT
      "39fcabed-ebca-4c4b-a052-f919c2ec34e3", //LMCH
      "c20c2938-4ef5-437f-8cb8-709f12901fae", //LMPR
      "6103dd13-a800-41c3-9361-54f819315524", //LMLM
      "8bb634de-47a5-4ace-95b4-f22eca721bff", //LMTR
      "42490f16-14ee-43f3-9b11-90f1c2f3b2f4", //LPYM
      "c1e4c1d6-14f5-402b-bb0c-4f0aa5b821d5", //LSAN
      "41753207-ae70-459b-8ffc-c7130e04d25d", //LVJB
      "a29d75a0-9c88-41da-8ece-62d53362e77b", //LVAB
      "d1e4c1d6-14f5-402b-bb0c-4f0aa5b821d6", //LVGA
      "545e9294-c4d2-4c12-9f1d-99ed322a6adf", //LMTN
    ].includes(assetId)
  );
};

export const promotionForAsset = (
  assetId: string,
  brand: Brand,
): Promotion | null =>
  isAssetPromotionEnabled(assetId, brand) ? promotionForBrand(brand) : null;

export const promotionForBrand = (brand: Brand): Promotion | null => {
  const isAfterPromotionStart = isAfter(new Date(), promotionStartDate);
  const isBeforePromotionEnd = isBefore(new Date(), promotionEndDate);
  if (
    ["libere", "koisi", "b48"].includes(brand.id) &&
    isAfterPromotionStart &&
    isBeforePromotionEnd
  ) {
    return {
      discount:
        brand.id === "koisi"
          ? { type: "fixed", amount: 5 }
          : brand.id === "b48"
            ? { type: "fixed", amount: 10 }
            : { type: "upTo", amount: 20 },
      promoCode: "STAYFRIDAY24",
      startDate: "01/12/2024",
      endDate: brand.id === "koisi" ? "31/01/2025" : "31/03/2025",
      maxNights: 3,
      loyaltyApplies: brand.id === "libere",
    };
  }
  return null;
};

export type Promotion = {
  discount: { type: "fixed" | "upTo"; amount: number };
  promoCode: string;
  startDate: string;
  endDate: string;
  maxNights: number;
  translateKey?: string;
  loyaltyApplies?: boolean;
};
